:root {
  --amplify-colors-background-primary: #fff;
  --amplify-shadows-medium: transparent;
  --amplify-fonts-default-variable: 'Montserrat', sans-serif;
  --amplify-fonts-default-static: 'Montserrat', sans-serif;
  --amplify-components-heading-3-font-size: var(--amplify-font-sizes-large);
  --amplify-components-heading-3-font-weight: var(--amplify-font-weights-semibold);
  --amplify-components-button-primary-background-color: #2e3038;
  --amplify-components-button-link-color: #2e3038;
  --amplify-components-button-link-hover-color: #fff;
  --amplify-components-button-link-hover-background-color: #f9be60;
  --amplify-components-button-primary-hover-background-color: #2e3038cf;
  --amplify-components-field-font-size: var(--amplify-font-sizes-small);
  --amplify-components-button-border-color: #2e3038;
  --amplify-components-fieldcontrol-error-border-color: '#ff6464';
}
