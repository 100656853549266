@media print {
  .no-print {
    display: none !important;
  }
  .print {
    display: block !important;
  }
  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 13mm 12mm;
  }

  html,
  body {
    margin: 0;
    background: #fff;
    overflow: visible;
  }
}
